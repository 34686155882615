export default {
 "menu.home": "首页",
 "menu.workbench": "工作台",
 "menu.lecturer":"讲师管理",
 "menu.lecturer.add":"新增讲师",
 "menu.lecturer.detail":"讲师详情",
 "menu.course":"课程管理",
 "menu.course.courseCategory":"课程分类",
 "menu.course.course":"课程编辑",
 "menu.instructor":"指导员管理",
 "menu.finance":"财务管理",
 "menu.finance.company":"企业充值",
 "menu.finance.instructor":"指导员佣金",
 "menu.finance.lecturer":"讲师工资",
 "menu.company":"企业管理",
 "menu.banner":"轮播图管理",
 "menu.message":"消息推送",
 "menu.employee":"人员管理",
};
