// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/app/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/app/src/layouts/UserLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "name": "login",
            "path": "/user/login",
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "name": "工作台",
                "icon": "workbench-solid",
                "path": "/platform/businessrule",
                "exact": true
              },
              {
                "name": "讲师管理",
                "icon": "teacher-solid",
                "path": "/lecturer",
                "exact": true
              },
              {
                "name": "课程管理",
                "icon": "course-solid",
                "path": "/course",
                "routes": [
                  {
                    "path": "/course/courseCategory",
                    "name": "课程分类",
                    "exact": true
                  },
                  {
                    "path": "/course/course",
                    "name": "课程编辑",
                    "exact": true
                  }
                ]
              },
              {
                "name": "指导员管理",
                "icon": "instructor-solid",
                "path": "/instructor",
                "exact": true
              },
              {
                "name": "企业管理",
                "icon": "company-solid",
                "path": "/company",
                "exact": true
              },
              {
                "name": "财务管理",
                "icon": "finance-solid",
                "path": "/finance",
                "routes": [
                  {
                    "path": "/finance/lecturer",
                    "name": "讲师工资",
                    "exact": true
                  },
                  {
                    "path": "/finance/instructor",
                    "name": "指导员佣金",
                    "exact": true
                  },
                  {
                    "path": "/finance/company",
                    "name": "企业充值",
                    "exact": true
                  }
                ]
              },
              {
                "name": "轮播图管理",
                "icon": "picture-solid",
                "path": "/banner",
                "exact": true
              },
              {
                "name": "消息推送",
                "icon": "notice-solid",
                "path": "/message",
                "exact": true
              },
              {
                "name": "人员管理",
                "icon": "personnel-solid",
                "path": "/employee",
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
